.pointer {
  cursor: pointer; }

.no-pointer {
  cursor: default; }

#body-container {
  height: calc(100vh - 66px - 1rem); 
}

.no-decor {
  text-decoration: none;
  color: inherit; }
  .no-decor:hover {
    text-decoration: none;
    color: inherit; }

.led-red {
  margin: 2px auto;
  width: 15px;
  height: 15px;
  background-color: #dd3f5b;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 5px, rgba(255, 0, 0, 0.5) 0 2px 12px; }

.led-green {
  margin: 2px auto;
  width: 15px;
  height: 15px;
  background-color: #2dc250;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 5px, #89ff00 0 2px 12px; }

.status-dashboard {
  position: absolute;
  right: 200px;
  top: 0.5rem; }

.dash-icon {
  font-size: 21px; }

.dash-section {
  height: 46px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.fade-enter,
.fade-appear {
  opacity: 0; }

.fade-enter-active,
.fade-enter-done,
.fade-appear-active,
.fade-appear-done {
  opacity: 1;
  transition: opacity 250ms; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms; }

.swipeR-appear,
.swipeR-enter {
  opacity: 0;
  transform: translateX(-50px); }

.swipeR-appear-active,
.swipeR-appear-done,
.swipeR-enter-active,
.swipeR-enter-done {
  opacity: 1;
  transform: translateX(0px);
  transition: transform 125ms, opacity 125ms; }

.swipeR-exit {
  opacity: 1; }

.swipeR-exit-active,
.swipeR-exit-done {
  opacity: 0;
  transform: translateX(50px);
  transition: transform 125ms, opacity 125ms; }

.swipeR-delay-appear {
  opacity: 0;
  transform: translateX(-50px); }

.swipeR-delay-enter {
  opacity: 0;
  transform: translateX(-50px); }

.swipeR-delay-appear-active {
  opacity: 1;
  transform: translateX(0px);
  transition: transform 125ms, opacity 125ms;
  transition-delay: 125ms; }

.swipeR-delay-appear-done,
.swipeR-delay-enter-active,
.swipeR-delay-enter-done {
  opacity: 1;
  transform: translateX(0px);
  transition: transform 125ms, opacity 125ms; }

.swipeR-delay-exit {
  opacity: 1; }

.swipeR-delay-exit-active,
.swipeR-delay-exit-done {
  opacity: 0;
  transform: translateX(50px);
  transition: transform 125ms, opacity 125ms; }

.swipeD-enter,
.swipeD-appear {
  opacity: 0;
  transform: translateY(-50px); }

.swipeD-appear-active,
.swipeD-appear-done,
.swipeD-enter-active,
.swipeD-enter-done {
  opacity: 1;
  transform: translateY(0px);
  transition: transform 125ms, opacity 125ms; }

.scrollD-enter {
  overflow: hidden;
  max-height: 95px; }

.scrollD-enter-active {
  overflow: hidden;
  max-height: var(--max);
  transition: max-height 300ms cubic-bezier(0.61, 1, 0.88, 1); }

.scrollD-exit {
  overflow: hidden;
  height: var(--max); }

.scrollD-exit-active {
  overflow: hidden;
  height: var(--min);
  transition: height 300ms cubic-bezier(0.61, 1, 0.88, 1); }

.scrollD-json-enter {
  overflow: hidden;
  min-height: 47px;
  max-height: 47px; }

.scrollD-json-enter-active {
  overflow: hidden;
  min-height: 47px;
  max-height: var(--max);
  transition: max-height 400ms linear; }
 
#rtkTable{
  white-space: nowrap;
  width: max-content;
}

td img{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.table-dark-custom{
  background-color: #a4a4a4;
  color:#333;
}

.table-head-title{
  font-size: 18px;
}

.table-header tr th, .table-body-data tr td{
  border:1px solid #333;
}

#alertModal{
  text-align:center;
}

#alertModal td{
  vertical-align: middle;
  text-align:left;
}

#alertModalHead {
  text-align:left;
}

.tableBorderDark > tr > td{
  border: 1px solid #646566;
  border-left:1.2px solid #646566;
  padding: 4px;
}
.ssoHeader{
  border-bottom: 1px solid #dee2e6;
  font-weight: bold;
}

/* .ssoMainblock + .modal-backdrop{
opacity:1;
background-color:#ffffff;
} */

.removeBoder{
border-top: none;
justify-content: center;
}

.loading-spin {
  font-size : 100px;
  color : #1492e6;
  -webkit-animation-name: loading-anim;
  animation-name: loading-anim;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
 -webkit-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-direction: normal;
  animation-direction: normal;
}
@keyframes loading-anim {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn)
  }
}

.custom-switch-disabled.custom-control-input:disabled~.custom-control-label::before{
  background-color : #c5c7c8;
}