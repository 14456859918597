i.small-icon {
  font-size: 16px; }

button.small-button {
  padding: 0.05rem 0.25rem; }

button.extra-small {
  padding: 0;
  line-height: 1.25;
  font-size: .7rem; }
